


































import { mapState } from 'vuex';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    TheAwaitingConfirmationIcon: () => import('@/components/icons/TheAwaitingConfirmationIcon.vue'),
    TheConfirmedIcon: () => import('@/components/icons/TheConfirmedIcon.vue'),
    TheNotConfirmedIcon: () => import('@/components/icons/TheNotConfirmedIcon.vue'),
    TheUserAttendIcon: () => import('@/components/icons/TheUserAttendIcon.vue'),
    LikeToAttend: () => import('@/components/partials/LikeToAttend.vue'),
  },
  computed: {
    ...mapState(['museumRole']),
  },
})
export default class LikeToAttendAction extends Vue {
  @Prop() event!: any;

  isLoader = false;
  isRequested = false;
  showForm = false;

  get actionDisabled() {
    return !!this.activeBooking;
  }

  get actionLabel() {
    return this.activeBooking ? this.activeBooking.status_for_human : this.ticketRequestLabel;
  }

  get activeBooking() {
    if (this.event.activeBooking) {
      return this.event.activeBooking;
    }
    if (this.isRequested) {
      return {
        is_awaiting_status: true,
        is_confirmed_status: false,
        is_not_confirmed_status: false,
        status_for_human: 'Awaiting confirmation',
      };
    }
    return null;
  }

  get showAction() {
    return this.activeBooking || (!this.event.is_expired && !this.event.fully_booked);
  }

  get ticketRequestLabel() {
    return this.event.ticketRequestLabel || "I'd Like to Attend";
  }

  @Watch('showForm', { immediate: true })
  watchMinServiceDate(isShow: boolean) {
    this.$store.dispatch(isShow ? 'disableScroll' : 'enableScroll');
  }

  showLikeRequested() {
    this.isRequested = true;
    this.showForm = false;
  }

  open() {
    if (!this.museumRole || this.$route.name === 'academy') {
      this.showForm = true;
      return;
    }

    if (this.$route.name === 'event') {
      if (!this.event.is_virtual_type && !this.event.enthusiast_membership_level) {
        this.$emit('show-museum-modal');
      } else {
        this.showForm = true;
      }
    }

    if (this.$route.name === 'art-fair' || this.$route.name === 'art-trip' || this.$route.name === 'miniCultivist') {
      this.$emit('show-museum-modal');
    }
  }

  close() {
    this.showForm = false;
  }
}
